.App {
  text-align: center;
}

#root {
  max-width: 1280px;

  background: black;
  color: white !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.header {
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header > div > div {
  margin-left: 1rem;
  margin-right: 1rem;
  /* border-radius: 5px; */
  padding: 7px 10px;
  border: 3px solid #d9af7d;
  /* background: #c49c74; */
}
.header > .logo-div {
  width: 20%;
}

.cnt {
  height: 100vh;
  width: auto !important;
  padding: 1rem;
}
.menus {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 80%;
}
.logo {
  width: 50px;
  height: 49px;
  border-radius: 50%;
  padding: 0px !important;
  border: 2px solid #d9af7d;
}
.main-content {
  height: 90%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-image: url("/src/assets/logo.jpeg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.footer-content {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: end;
}

.footer {
  height: 10%;
  width: 100%;
}

.main-sub {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.main-sub > p > span {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
@media screen and (min-width: 300px) {
  .header > div > div {
    margin: 0 !important;
  }
}
